import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';
import { verticalLayout } from 'forms/AntragForm/ui-schemas/elements/verticalLayout';

export const uiSchema = verticalLayout([
    group([
        control('field1736'),
        {
            type: 'Group',
            label: 'Platzzahlreduzierungen',
            scope: '#/properties/platzzahlreduzierungenFiktivesSalg',
            elements: [
                {
                    type: 'FormExtendButton',
                    label: 'Platzzahlreduzierung (nur Dortmunder Modell) hinzufügen',
                    scope: '#/properties/platzzahlreduzierungenFiktivesSalg',
                    uiSchema: {
                        type: 'CustomVerticalLayout',
                        elements: [
                            {
                                type: 'Control',
                                scope: '#/properties/field1737',
                                options: {
                                    firstControl: true,
                                },
                            },
                            {
                                type: 'Control',
                                scope: '#/properties/field1738',
                            },
                        ],
                    },
                },
            ],
        },
        control('field1739'),
        control('field1740'),
        control('field1741'),
        control('field1742'),
        control('field1743'),
        control('field1744'),
    ]),
]);
