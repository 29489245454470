import { control } from 'forms/AntragForm/ui-schemas/elements/control';
import { group } from 'forms/AntragForm/ui-schemas/elements/group';

export const uiSchema = {
    type: 'CustomVerticalLayout',
    elements: [
        group([
            {
                type: 'TenorTable',
                elements: [
                    control('tenor1', { controlOnly: true }),
                    control('tenor2', { controlOnly: true }),
                    control('tenor3', { controlOnly: true }),
                    control('tenor4', { controlOnly: true }),
                    control('tenor5', { controlOnly: true }),
                    control('tenor6', { controlOnly: true }),
                    control('tenor7', { controlOnly: true }),
                ],
                styles: {
                    marginBottom: 32,
                },
            },
            control('field0900t'),
        ]),
    ],
};
