import React, { FocusEvent, useMemo, useRef } from 'react';
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';

import { THOUSAND_SEPARATOR } from 'utilities/Utils';

export interface NummerFormatProps extends Omit<NumberFormatProps, 'getInputRef' | 'onValueChange'> {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    scale?: number;
}

export const DezimalFormat: React.FC<NummerFormatProps> = React.forwardRef(
    ({ onChange, min, scale, onBlur, ...props }, ref) => {
        const valueRef = useRef<string>('');
        const allowNegative = useMemo(() => min === undefined || min < 0, [min]);

        return (
            <NumberFormat
                getInputRef={ref}
                onValueChange={(values: NumberFormatValues) => {
                    valueRef.current = values.value;
                    onChange({
                        target: {
                            name: props.name!,
                            value: valueRef.current,
                        },
                    });
                }}
                onBlur={(e: FocusEvent<HTMLInputElement>) => {
                    onBlur?.({
                        ...e,
                        target: {
                            ...e.target,
                            name: props.name!,
                            value: valueRef.current,
                        },
                    });
                }}
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator=","
                decimalScale={scale || 2}
                fixedDecimalScale={true}
                allowNegative={allowNegative}
                isNumericString
                {...props}
            />
        );
    }
);

export const GanzzahlFormat: React.FC<NummerFormatProps> = React.forwardRef(
    ({ onChange, min, name, ...props }, ref) => {
        const allowNegative = useMemo(() => min === undefined || min < 0, [min]);

        return (
            <NumberFormat
                getInputRef={ref}
                onValueChange={(values: NumberFormatValues) => {
                    onChange({
                        target: {
                            name: name!,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator=","
                decimalScale={0}
                fixedDecimalScale={true}
                allowNegative={allowNegative}
                {...props}
            />
        );
    }
);
