import * as React from 'react';
import { ChangeEvent, KeyboardEventHandler, useCallback } from 'react';
import { FormControl, TextField } from '@mui/material';

import { ErrorList } from 'forms/controls/components/ErrorList';
import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { useScrollInView } from 'forms/hooks/useScrollInView';
import { useTempControlData } from 'forms/hooks/useTempControlData';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { GridLayoutConfig } from 'forms/types/UiSchemaTypes';

import { RowControl } from './RowControl';

const TextAreaEinzeiligControlComponent: React.FC<CustomControlProps<string>> = ({
    path,
    label,
    required,
    config,
    data,
    handleChange,
    uischema,
    readonly,
    disabled,
    hasErrors,
    errors,
    showFieldNumberLabels,
    gridLayout,
    onTouched,
    id,
}) => {
    const eventToData = useCallback((e: ChangeEvent<HTMLInputElement>) => e.target.value, []);
    const { tempData, handleValueChange, handleOnBlur } = useTempControlData(
        data,
        path,
        errors,
        handleChange,
        eventToData,
        onTouched,
        {
            submitOnChange: true,
        }
    );
    const ref = useScrollInView<HTMLDivElement>();

    const defaultGrid: GridLayoutConfig = {
        fieldnumber: 1,
        label: 3,
        input: 3,
    };
    const grid = { ...defaultGrid, ...gridLayout };

    return (
        <RowControl
            name={path}
            label={uischema?.options?.noLabel === true ? undefined : label}
            required={required}
            multiline={uischema?.options?.multiline}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={grid}
            labelForId={id}
            config={config}
            paths={[path]}
        >
            <FormControl fullWidth ref={ref} error={hasErrors}>
                {readonly ? (
                    <ReadonlyValueText path={path} hasErrors={hasErrors} errors={errors} text={data || '-'} />
                ) : (
                    <>
                        <TextField
                            id={id}
                            error={hasErrors}
                            disabled={disabled}
                            required={required}
                            onBlur={handleOnBlur}
                            value={tempData ?? ''}
                            onKeyDown={preventEnter}
                            onChange={handleValueChange}
                            inputProps={{ 'data-cy': `form_${path}` }}
                            multiline
                            fullWidth
                            autoFocus={
                                uischema.options?.focus ||
                                (uischema.options?.firstControl && config.autoFocusFirstControl)
                            }
                            helperText={hasErrors ? <ErrorList errors={errors} /> : ' '}
                        />
                    </>
                )}
            </FormControl>
        </RowControl>
    );
};

const preventEnter: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') e.preventDefault();
};

export const TextAreaEinzeiligControl = withCustomControlProps(TextAreaEinzeiligControlComponent);
